// This file provides an interface for all our api services defined in the services file of each module.
import axios from "axios";
import Auth from "./Auth";
import { getCorporate } from "helpers/get-tenant";
import { getDomainFromLocalStorage } from "helpers/getDomainFromLocalStorage";
import authService from "../services/authService";

export const base_url = 
 process.env.REACT_APP_BASE_API_URL

const Api = axios.create({ baseURL: base_url });

const corporate = getCorporate();
const { language } = getDomainFromLocalStorage(
  !!corporate ? "corporate" : "business"
);


const languageMapping = {
  "English (UK)": "en",
  "French (FR)": "fr",
};

Api.interceptors.request.use(
  (config) => {
    // This adds an authorization key to config object if a token exists.
    if (Auth.isAuthenticated(!!corporate ? "corporate" : "business") === true) {
      const token = Auth.getToken(!!corporate ? "corporate" : "business");
      config.headers.common["Authorization"] = `Bearer ${token}`;
      config.headers.common[
        "Accept-Language"
      ] = `culture=${languageMapping[language]}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

Api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const token = await authService.refreshAccessToken(
        !!corporate ? "corporate" : "business"
      );
      const newRequest = {
        ...originalRequest,
        headers: {
          ...originalRequest.headers,
          Authorization: `Bearer ${token}`,
        },
      };
      return Api(newRequest);
    }

    return Promise.reject(error);
  }
);

export const CalendlyApi = axios.create({
  baseURL: process.env.REACT_APP_CALENDLY_API_BASE_URL
});

CalendlyApi.interceptors.request.use(
  (config) => {
    config.headers.common["Authorization"] = `Bearer ${process.env.REACT_APP_CALENDLY_PERSONAL_ACCESS_TOKEN}`;

    return config;
  },
  (error) => Promise.reject(error)
)

export default Api;
