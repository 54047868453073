export const getDomainFromLocalStorage = (type = "business") => {
  try {
    const typeMapping = {
      business: "domain",
      corporate: "corporateDomain",
    };

    return JSON.parse(localStorage.getItem(typeMapping[type])) || {};
  } catch (e) {
    return null;
  }
};
