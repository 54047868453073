import { Result } from "antd";

const PageNotFound = () => {
  return (
    <Result
      status="500"
      title="Oops, something went wrong"
      subTitle="Try to refresh this page or feel free to contact us if the problem persists."
    />
  );
};

export default PageNotFound;
