import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row } from "antd";
import { makeStyles } from "@material-ui/styles";
import Logo from "assets/Health.gif";

/**
 * @module
 * SuspenseFallback renders a fallback UI used for Suspense
 * This component also accepts a delay prop that can be used to set how many seconds
 * to delay showing the spinner
 */
const SuspenseFallback = (props) => {
  const classes = useStyles();
  const { delay } = props;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), delay);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, []);

  return (
    isVisible && (
      <Row align="middle" justify="center" className={classes.spinnerContainer}>
        <img src={Logo} alt="logo" />
      </Row>
    )
  );
};

const useStyles = makeStyles({
  spinnerContainer: {
    height: "100vh",
    width: "100%",
    "& .logo": {
      animationDuration: "1.5s",
      animationFillMode: "both",
      animationIterationCount: "infinite",
      animationName: "$logo",
      animationTimingFunction: "ease-in-out",
    },
  },
  "@keyframes logo": {
    "0%": {
      opacity: 0.5,
      transform: "scale(0.9)",
    },
    "100%": {
      opacity: 1,
      transform: "scale(1)",
    },
  },
});

SuspenseFallback.propTypes = {
  delay: PropTypes.number,
};

SuspenseFallback.defaultProps = {
  delay: 300,
};

export default React.memo(SuspenseFallback);
