/* This Project initially used subdomain based routing for multitenancy, 
hence the name useSubDomain
 This was later changed to a path based routing . 
 
 ***MAJOR ROUTE CONVENTIONS FOR CONTEXT****

 Old

super admin login => admin.leadwayhealth.com

business admin login => [businessName].leadwayhealth.com

corporate login => [businessName].leadwayhealth.com/corporate-login

 
New
 
super Admin login  => leadwayhealth.com/admin/login

business admin login => leadwayhealth.com/[businessName]/login  

corporate  login => leadwayhealth.com/[businessName]/corporate-login
 
 */

import { useState, useEffect } from "react";
import { getCorporate, getSubdomain } from "helpers/get-tenant";
import domainServices from "services/domainServices";
import { colors } from "../Css";

const useSubdomain = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState(null);
  const domain = getSubdomain();
  const corporate = getCorporate();

  const handleSetCorporate = async () => {
    try {
      const response = await domainServices.getDomainProps({
        queryKey: ["domain", corporate],
      });
      localStorage.setItem("corporateDomain", JSON.stringify(response.data));
      setType("corporate");
    } catch (e) {
      console.error(e.message);
    }
  };

  //:TODO Refactor to use react query useQuery
  const handleGetDomain = async () => {
    try {
      // alert(`domain ${domain}`);
      // alert(`corporate ${corporate}`);

      const response = await domainServices.getDomainProps({
        queryKey: ["domain", domain],
      });
      // alert(response);
      const { data } = response;

      if (!corporate) {
        localStorage.setItem("domain", JSON.stringify(data));
      }
      if (corporate) {
        await handleSetCorporate();
      }
      setType(!!corporate ? "corporate" : "business");
    } catch (e) {
      localStorage.setItem(
        "domain",
        JSON.stringify({
          type: "invalid",
          name: domain,
          subDomain: domain,
          colour: colors.primary,
        })
      );
      setType("invalid");
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (domain === "admin") {
        localStorage.setItem(
          "domain",
          JSON.stringify({
            type: "admin",
            name: "admin",
            subDomain: "admin",
            colour: colors.primary,
          })
        );
        setType("admin");
      } else {
        await handleGetDomain();
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading, type };
};

export default useSubdomain;
