import Api from "utils/Api";
import handleApiError from "../utils/handleApiError";

const getDomainProps = async ({ queryKey }) => {
  const [, params] = queryKey;

  try {
    const response = await Api.get(`/businesses/domain/${params}`);
    return response.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const domainServices = {
  getDomainProps,
};

export default domainServices;