import { getDomainFromLocalStorage } from "helpers/getDomainFromLocalStorage";
import jwt_decode from "jwt-decode";

const isAuthenticated = (type = "business") => {
  try {
    const token = localStorage.getItem(
      type === "corporate" ? "corporateRefreshToken" : "refreshToken"
    );
    const decodedToken = jwt_decode(token);

    const { exp } = decodedToken;
    const currentTime = Date.now() / 1000;

    return exp > currentTime ? true : false;
  } catch (e) {
    return false;
  }
};

const getDecodedJwt = (type = "business") => {
  const token = localStorage.getItem(
    type === "corporate" ? "corporateToken" : "token"
  );
  return jwt_decode(token);
};

const setToken = (token, type = "business") => {
  localStorage.setItem(
    type === "corporate" ? "corporateToken" : "token",
    token
  );
};

const setRefreshToken = (refreshToken, type = "business") => {
  localStorage.setItem(
    type === "corporate" ? "corporateRefreshToken" : "refreshToken",
    refreshToken
  );
};

const getToken = (type = "business") => {
  return localStorage.getItem(
    type === "corporate" ? "corporateToken" : "token"
  );
};

const getRefreshToken = (type = "business") => {
  return localStorage.getItem(
    type === "corporate" ? "corporateRefreshToken" : "refreshToken"
  );
};

const removeToken = () => {
  localStorage.removeItem("token");
};

const removeDomainObj = () => {
  localStorage.removeItem("domain");
};

const logOut = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  window.location.replace("/");
};

const logOutBusiness = () => {
  const { subDomain } = getDomainFromLocalStorage("business") || {};
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  window.location.replace(`/${subDomain}`);
};

const logOutCorporate = () => {
  const { subDomain } = getDomainFromLocalStorage("business") || {};

  localStorage.removeItem("corporateToken");
  localStorage.removeItem("corporateDomain");
  localStorage.removeItem("corporateRefreshToken");
  const url = `${window.location.protocol}//${window.location.host}/${subDomain}/corporate-login`;
  window.location.replace(url);
};

const getPermissions = (type = "business") => {
  try {
    const token = localStorage.getItem(
      type === "corporate" ? "corporateToken" : "token"
    );
    const decoded = jwt_decode(token);
    const { Permission: permissions } = decoded;
    return permissions;
  } catch (error) {
    return [];
  }
};

const Auth = {
  isAuthenticated,
  getDecodedJwt,
  setToken,
  setRefreshToken,
  getRefreshToken,
  getToken,
  removeToken,
  removeDomainObj,
  logOut,
  logOutBusiness,
  logOutCorporate,
  getPermissions,
};

export default Auth;
