// Common utils such as colors, fontSizes, fontWeight and common styles falls here
// Common styles could be boxShadow style or any globally used styles.
export const colors = {
  primary: "#FF8A00",
  background: "#E5E5E5",
  title: "#636E95",
  title2: "#242F57",
  textColor1: "#636E95",
  secondary: "",
  btnBg: "#7C88B1",
  btnColor: "#EAEDF7",
  success: "#00BFA6",
  cardTopBorder: "#FFCA2833",
  black: "#000",
  white: "#fff",
  error: "#E8150B",
  overlay: "rgba(0,0,0,.4)",
  largeTitle: "#1E1F20",
  subtitle: "#7F85A2",
  text: "#242F57",
  tableHeadBackground: "#FAFCFE",
  tableHeadColor: "#8181A5",
  tableBorderColor: "#ECECF2",
  tableDataColor: "#7C88B1",
  tablePaginationLinkBg: "#F5F5FA",
  tableVariantBackground: "#FAFAFA",
  tablePaginationItemColor: "#1C1D21",
  backLink: "#97A0C3",
  uploadBtn: "#E2E5F0",
  inputDisabled: "#F5F5F5",
  inputTextColor: "#8181A5",
  dashboardCard1Bg: "#ADFBE7",
  dashboardCard1SubBg: "#00BC8C",
  blueText: "#6863FB",
  tagBg: "#F4FAFF",
  borderColor: "#D2D5E1",
  darkText: "#182E5F",
};

export const themeColors = {
  primaryColor: "#FF8A00",
  secondaryColor: "#7C88B1",
  tertiaryColor: "#369AFE",
  card1: {
    textColor: "#242F57",
    iconColor: "#1FD0A3",
    background: "#ADFBE7",
  },
  card2: {
    textColor: "#F15A24",
    iconColor: "#FF8A00",
    background: "#FFF1DE",
  },
  card3: {
    textColor: "#1976D2",
    iconColor: "#5E81F4",
    background: "#D0ECFF",
  },
};

export const fontSizes = {
  xxsmall: 8,
  xsmall: 10,
  small: 12,
  medium: 14,
  large: 16,
  xlarge: 18,
  xxlarge: 20,
  title: 24,
  largeTitle: 36,
};

export const fontWeight = {
  bold: 700,
  medium: 600,
  normal: 500,
  regular: 400,
  light: 300,
};

export const spaces = {
  small: 10,
  normal: 15,
  medium: 20,
  large: 30,
};

export const fontFamily = {
  primary: '"Inter", sans-serif',
};

export const boxShadow = {
  small: `0px 2px 4px rgba(28, 41, 90, 0.0367952)`,
  large: `6px 16px 42px rgba(99, 110, 149, 0.25)`,
};
