export const DOMAIN = process.env.REACT_APP_DOMAIN || "localhost:3000";

export const getSubdomain = () => {
  let pathname = window.location.pathname;
  const hasBusinessDomain = !!pathname.split("/")[1];

  // console.log(hasBusinessDomain);
  if (hasBusinessDomain) {
    // console.log(pathname.split("/")[1]);
    if (pathname.startsWith("/cp")) {
      return pathname.split("/")[2];
    } else {
      return pathname.split("/")[1];
    }
  }
  return "admin";
};

export const getCorporate = () => {
  let pathname = window.location.pathname.toLowerCase();
  // console.log(pathname);
  if (pathname.startsWith("/cp")) return pathname.split("/")[2];
  return undefined;
};
