import React from "react";
import Routes from "routes";
import { ThemeProvider } from "@material-ui/styles";
import { Helmet } from "react-helmet";
import { useIdleTimer } from "react-idle-timer";
import useSubdomain from "hooks/useSubdomain";
import SuspenseLoader from "components/SuspenseFallback";
import "./App.less";
import { colors, themeColors } from "./Css";
import defaultFavicon from "assets/faviconDefault.png";
import { getDomainFromLocalStorage } from "helpers/getDomainFromLocalStorage";
import Auth from "utils/Auth";
import { getCorporate } from "helpers/get-tenant";
import { IdleTimeout } from "utils/constant";

function App() {
  const { isLoading, type } = useSubdomain();
  // console.log(type);
  const domainObj = getDomainFromLocalStorage(
    type === "corporate" ? "corporate" : "business"
  );
  // console.log(domainObj);
  const { colour: primary, themeTemplate } = domainObj || {};
  const { primaryColor, secondaryColor, tertiaryColor, themeTemplateCards } =
    themeTemplate || {};
  const cards = {};
  themeTemplateCards?.forEach((card) => {
    const { background, iconColor, textColor, cardName } = card;
    cards[cardName] = { background, iconColor, textColor };
  });

  // Still going to look into this....This ensures that there's a color theme available
  const colorTheme = {
    ...colors,
    ...themeColors,
    primary: primary || primaryColor,
    primaryColor: primary || primaryColor,
    secondaryColor,
    tertiaryColor,
    ...cards,
  };

  const handleOnIdle = () => {
    const isCorporate = getCorporate();
    const isTokenExist = Auth.isAuthenticated(
      isCorporate ? "corporate" : "business"
    );

    if (isTokenExist && !!isCorporate) Auth.logOutCorporate();
    if (isTokenExist && !isCorporate) Auth.logOut();
  };

  useIdleTimer({
    timeout: IdleTimeout,
    onIdle: handleOnIdle,
    stopOnIdle: true,
    crossTab: {
      emitOnAllTabs: true,
    },
    debounce: 500,
  });

  return (
    <ThemeProvider theme={colorTheme}>
      <Helmet>
        <link rel="icon" href={`${domainObj?.favicon || defaultFavicon}`} />
      </Helmet>
      {isLoading ? <SuspenseLoader /> : <Routes type={type} />}
    </ThemeProvider>
  );
}

export default App;
