import Api from "utils/Api";
import handleApiError from "utils/handleApiError";
import Auth from "utils/Auth";

const refreshAccessToken = async (type = "business") => {
  try {
    const accessToken = Auth.getToken(type);
    const refreshToken = Auth.getRefreshToken(type);
    const {
      data: { data },
    } = await Api.post("users/refresh-token", { accessToken, refreshToken });
    Auth.setToken(data.accessToken, type);

    return data.accessToken;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const setCorporatePassword = async (payload) => {
  try {
    const response = await Api.post("users/cp-set-password", payload);
    return response.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

//Custom here is used to indicate the user was created on the internal system, the endpoint above is for a user created on prognosis
const setCustomCorporatePassword = async (payload) => {
  try {
    const response = await Api.post("users/cp-users-set-password", payload);
    return response.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const corporateLogin = async (payload) => {
  try {
    const response = await Api.post("users/cp-login", payload);
    return response.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const corporateInitialOpen = async (payload) => {
  try {
    const response = await Api.post("enrollee/initial-open", payload);
    return response.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const selfSignup = async (payload, code, batchid) => {
  try {
    const response = await Api.post(
      `enrollee/self-signup?code=${code}&batchId=${batchid}`,
      payload
    );
    return response.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const getGenders = async ({ queryKey }) => {
  const [, id] = queryKey;

  try {
    const response = await Api.get(`/listValues/business/${id}/gender`);
    return response.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const getMaritalStatus = async ({ queryKey }) => {
  const [, id] = queryKey;

  try {
    const response = await Api.get(`/listValues/business/${id}/marital-status`);
    return response.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const getRelationships = async ({ queryKey }) => {
  const [, id] = queryKey;

  try {
    const response = await Api.get(`/listValues/business/${id}/relationship`);
    return response.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const getPlanTypes = async ({ queryKey }) => {
  const [, id] = queryKey;

  try {
    const response = await Api.get(`/listValues/business/${id}/plans`);
    return response.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const setUp2FA = async (payload) => {
  try {
    const response = await Api.post(`/users/setup-2fa`, payload);
    return response.data.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const validateOTP = async (payload) => {
  const { email, otp, corporateId } = payload;
  try {
    const response = await Api.post(
      `/users/cp-login/validate-otp?otp=${otp}&email=${email}&corporateId=${corporateId}`
    );
    return response.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

//This is for resetting password from the settings area, not from login
const cpResetPassword = async (payload) => {
  try {
    const response = await Api.post(`/users/cp-reset-password`, payload);
    return response.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const resetPassword = async (payload) => {
  try {
    const response = await Api.post(`/users/reset-password`, payload);
    return response.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const corporatesOfEmail = async (payload) => {
  try {
    const response = await Api.post(`/users/email-corporates`, payload);
    return response.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const userCorporates = async (payload) => {
  try {
    const response = await Api.post(`/users/cp-user-corporates`, payload);
    return response.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const verifyToken = async (payload) => {
  try {
    const response = await Api.post(`/users/verify-token`, payload);
    return response.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const setPassword = async (payload) => {
  try {
    const response = await Api.post(`/users/set-password`, payload);
    return response.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const getUserDetails = async ({ queryKey }) => {
  const [id] = queryKey;
  try {
    const response = await Api.get(`/users/${id}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleApiError(e));
  }
};

const authService = {
  setCorporatePassword,
  userCorporates,
  corporateLogin,
  corporateInitialOpen,
  selfSignup,
  getPlanTypes,
  getRelationships,
  getMaritalStatus,
  getGenders,
  setUp2FA,
  validateOTP,
  cpResetPassword,
  resetPassword,
  verifyToken,
  setPassword,
  setCustomCorporatePassword,
  getUserDetails,
  refreshAccessToken,
  corporatesOfEmail,
};

export default authService;
