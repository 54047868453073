import React from "react";
import ReactDOM from "react-dom";
import { jssPreset, StylesProvider } from "@material-ui/styles";
import jssPluginSyntaxExtend from "jss-plugin-extend";
import { create } from "jss";
import App from "./App";
import reportWebVitals from "reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./index.css";
import "normalize.css";

const jss = create({
  plugins: [...jssPreset().plugins, jssPluginSyntaxExtend()],
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 5000,
    },
    mutations: {
      throwOnError: false,
    },
  },
});

ReactDOM.render(
  <StylesProvider jss={jss}>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </StylesProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
