import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SuspenseLoader from "components/SuspenseFallback";
import PageNotFound from "pages/PageNotFound";

export const PrivatePaths = {
  PRIVATE: "/private",
  INTEGRATION_EXAMPLE: "/integration-example",
  SUPER_ADMIN: "/admin",
  BUSINESS_ADMIN: "/business-admin",
  CORPORATE: "/corporate",
};

const Routes = ({ type }) => {
  let route;
  if (type === "admin") route = lazy(() => import("./SuperAdminRoutes"));
  if (type === "business") route = lazy(() => import("./BusinessAdminRoutes"));
  if (type === "corporate") route = lazy(() => import("./CorporateRoutes"));
  if (type === "invalid") route = PageNotFound;

  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Router>
        <Switch>
          <Route path="/" exact={false} component={route} />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default Routes;
